import React from "react";
import style from "./SpacesCommentCard.module.scss";
import { MdStar } from "react-icons/md";
import Avatar from "../Atoms/Avatar";
import { times } from "lodash-es";

const SpacesCommentCard = props => {
  const { text, stars, date, avatar, user } = props;
  return (
    <div className={`card ${style.commentCard}`}>
      <div className={`card-content ${style.commentCardContent}`}>
        <div className="columns" breakpoint="mobile">
          <div
            mobile={{
              size: 12
            }}
            tablet={{ size: 12 }}
            desktop={{
              size: 12
            }}
            className={`column ${style.commentText}`}
          >
            <p>{text}</p>
          </div>

          <div
            mobile={{
              size: 12
            }}
            tablet={{ size: 12 }}
            desktop={{
              size: 12
            }}
            className={`column ${style.commentValoration}`}
          >
            <div className={style.detailRanking}>
              {times(5, index => {
                return (
                  <i
                    key={index}
                    className={`${index < stars ? style.activeStar : "default"
                      }`}
                  >
                    <MdStar />
                  </i>
                );
              })}
              <span> - {date} </span>
            </div>
          </div>
          <div
            mobile={{
              size: 8
            }}
            tablet={{ size: 6 }}
            desktop={{
              size: 6
            }}
            className={`column ${style.avatarCard}`}
          >
            <div className={style.detailRanking}>
              <Avatar rounded={true} src={avatar} />
              <p>{user}</p>
            </div>
          </div>
          <div
            mobile={{
              size: 4
            }}
            tablet={{ size: 6 }}
            desktop={{
              size: 6
            }}
            className={`column ${style.cardReactions}`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SpacesCommentCard;
