import React, { useState } from "react";
import style from "./DetailSpace.module.scss";
import Hero from "../../components/Banners/Hero";
import { Container, Section, Columns, Button } from "react-bulma-components";
import { MdFavorite, MdPlace, MdWatchLater } from "react-icons/md";
import SpacesCommentCard from "../../components/Cards/SpacesCommentCard";
import Description from "../../components/Description/Description";
import Calendar, { calendarId } from "../../components/Calendar/Calendar";
import Separate from "components/Atoms/Separate";
import SvgIcon from "components/Atoms/SvgIcon";
import RankingButton from "components/Organisms/RankingButton";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import endpoints from "api/URLconstants";
import API from "api/api";
import LoadingWide from "components/Loading/LoadingWide";
import { useSetFavorite, useDeleteFavorite } from "hooks/api.hook";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GiGreekTemple } from "react-icons/gi";
import { IoIosPeople, IoMdInformationCircle } from "react-icons/io";
import userStore from "contexts/userStore";

function SpaceIntro({ data }) {
  const [isFavorite, setIsFavorite] = useState(data?.is_favorite);
  const isLogged = userStore((state) => state.isLogged)();

  const scrollToCalendar = React.useCallback(() => {
    const calendarNode = document.getElementById(calendarId);

    if (calendarNode) {
      calendarNode.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const { handleFavorite } = useSetFavorite(() => setIsFavorite(true));
  const { deleteFavorite } = useDeleteFavorite(() => setIsFavorite(false));

  const handleClick = () => {
    if (isFavorite) {
      const payload = {
        is_favorite: false,
      };
      deleteFavorite(data.id, payload);
    } else {
      const payload = {
        is_favorite: false,
      };
      handleFavorite(data.id, payload);
    }
  };

  return (
    <>
      <Section>
        <Container>
          <Columns>
            <Columns.Column
              breakpoint="mobile"
              className={`is-two-thirds ${style.detailActions}`}
            >
              <div className={style.nameSpace}>
                <span>{data?.edifice_name}</span>
                <h1>{data?.name}</h1>
                <div className={style.detailColumn}>
                  <Columns>
                    <Columns.Column size={3}>
                      <RankingButton
                        ranking={data?.rating}
                        spaceId={data?.id}
                      />
                    </Columns.Column>
                    <Columns.Column>
                      <Button
                        disabled={!isLogged}
                        onClick={handleClick}
                        className="is-svg ml-4"
                        aria-label="añadir a favoritos"
                        title="añadir a favoritos"
                      >
                        <SvgIcon size="large">
                          <MdFavorite color={isFavorite ? "red" : "grey"} />
                        </SvgIcon>
                      </Button>
                    </Columns.Column>
                  </Columns>
                </div>
                <p className="mt-5">{data?.description}</p>
              </div>
            </Columns.Column>
            <Columns.Column className={style.bookButtonContainer}>
              <Button
                type="submit"
                className={`${style.detailTextButton} is-medium`}
                color="primary"
                onClick={scrollToCalendar}
              >
                Reservar
              </Button>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </>
  );
}
const DetailSpace = () => {
  const {
    params: { slug },
  } = useRouteMatch();

  const location = useLocation();
  const history = useHistory();

  const { data: response, isLoading } = useQuery({
    queryKey: [`${endpoints.space.list}${slug}`],
    queryFn: async (url) => {
      console.log(url.queryKey);

      return await API.get(url.queryKey[0]).catch((error) => {
        console.log({ error });
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
  });

  if (isLoading) {
    return <LoadingWide />;
  }

  const aboutThis = (item) => [
    {
      title: "Edificio",
      icon: <MdPlace />,
      isBold: true,
      data: [item?.edifice_name],
    },
    {
      title: "Tipo de espacio",
      icon: <GiGreekTemple />,
      isBold: true,
      data: item?.place_type ? item?.place_type["name"] : null,
    },
    {
      title: "Horarios",
      icon: <MdWatchLater />,
      isBold: true,
      isSchedule: true,
      data: [item?.schedule],
    },
    {
      title: "Capacidad",
      icon: <IoIosPeople />,
      isBold: true,
      data: item?.capacity + " personas",
    },
    {
      title: "Servicios",
      icon: <IoMdInformationCircle />,
      isBold: true,
      isServices: true,
      data: [item?.services],
    },
  ];

  return (
    <>
      <Hero background={response?.data.logo}></Hero>
      <SpaceIntro data={response?.data} />
      <Section>
        <Container>
          <Separate />
          <Description
            items={aboutThis(response?.data)}
            isTitle={"Acerca de este espacio"}
          />
          <Calendar
            spaceId={slug}
            spaceData={response?.data}
            dateFromQuery={location.state?.date}
            onGoToLogin={() =>
              history.push("/login", { fromPage: location.pathname })
            }
          />
          <Separate />
          <Columns breakpoint="mobile">
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{ size: 12 }}
              desktop={{
                size: 12,
              }}
            >
              <h2 className={style.subtextSection}>Reseñas</h2>
            </Columns.Column>
            <Columns.Column
              mobile={{
                size: 12,
              }}
              tablet={{ size: 12 }}
              desktop={{
                size: "two-thirds",
                offset: 2,
              }}
            >
              {response?.data !== undefined &&
              response?.data?.valorations?.length > 0 ? (
                response?.data?.valorations?.map((valoration, index) => (
                  <div key={"valoration" + valoration.id}>
                    <SpacesCommentCard
                      avatar={
                        valoration.user && valoration.user[0].photo
                          ? valoration.user[0].photo
                          : "https://as2.ftcdn.net/v2/jpg/04/10/43/77/1000_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg"
                      }
                      date={valoration.created}
                      stars={valoration.valoration}
                      user={valoration.username}
                      text={valoration.comment}
                    />
                  </div>
                ))
              ) : (
                <p>Aún no hay reseñas para este espacio </p>
              )}
            </Columns.Column>
          </Columns>
        </Container>
        <ToastContainer />
      </Section>
    </>
  );
};

export default DetailSpace;
