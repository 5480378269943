import React from "react";

import Switch from "@rakuten-rex/switch/Switch";
import style from "./RevealingBox.module.scss";

export default function RevealingBox({
  isChecked = false,
  label,
  children,
  onChange
}) {
  return (
    <>
      <Switch
        id={`switch-${label}`}
        label={label}
        name={label}
        checked={isChecked}
        onChange={() => onChange(!isChecked)}
      />
      {isChecked && <div className={`field ${style.child}`}>{children}</div>}
    </>
  );
}
