import { useQuery } from "@tanstack/react-query";
import { getExpiredReservation, getReservation } from "api/reservations";
import { getFavorites, getProfile } from "api/user";

export const fetchProfileData = async (id) => {
  const [profile, activeReservation, expiredReservation, favorites] =
    await Promise.all([
      getProfile(id),
      getReservation(),
      getExpiredReservation(),
      getFavorites(),
    ]);
  console.log({
    profile: profile.data,
    activeReservation: activeReservation.data,
    expiredReservation: expiredReservation.data,
    favorites: favorites.data,
  });

  return { profile, activeReservation, expiredReservation, favorites };
};
export function useProfileData(id) {
  return useQuery({
    queryKey: ["profileData", id],
    queryFn: getProfile,
    staleTime: 1000 * 60 * 60,
  });
}

export const useGetActiveReservation = () => {
  return useQuery({
    queryKey: ["activeReservation"],
    queryFn: getReservation,

    staleTime: 1000 * 60 * 60,
  });
};
export const useGetExpiredReservation = () => {
  return useQuery({
    queryKey: ["expiredReservation"],
    queryFn: getExpiredReservation,

    staleTime: 1000 * 60 * 60,
  });
};
export const useGetFavorites = () => {
  return useQuery({
    queryKey: ["favorites"],
    queryFn: getFavorites,

    staleTime: 1000 * 60 * 60,
  });
};
