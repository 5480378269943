import API from "./api";
import endpoints from "./URLconstants";

export const getPlaceTypes = (url, params) => {
  return API.get(endpoints?.places.placeTypes);
};

export const getFavoritePlaces = (url, params) =>
  API.get(`${endpoints.base}${url}`, { params });

export const getPopularPlaces = (url, params) =>
  API.get(`${endpoints?.places.popular}${url}`, { params });
