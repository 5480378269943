import React, { useState } from "react";

import styles from "./AuthForm.module.scss";

export default function AuthForm({ children }) {
  return (
    <div className="section">
      <div className="container">
        <div className={`columns ${styles.authFormWrapper}`}>
          <div className="column is-one-third-tablet">
            <div className={styles.auth}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const InfoWrapper = ({ children }) => {
  return <div className={styles.infoWrapper}>{children}</div>;
};

const Error = ({ children }) => {
  const [isShown, setIsShown] = useState(true);

  return (
    isShown && (
      <div className="notification is-danger is-light">
        <p>{children}</p>
        <button className="button" remove onClick={() => setIsShown(false)} />
      </div>
    )
  );
};

const FormProgress = ({ isLoading }) => {
  return (
    <div>
      {isLoading && <progress className="progress" color="primary" size="small" max={100} />}
    </div>
  );
};

AuthForm.Error = Error;
AuthForm.Progress = FormProgress;
AuthForm.Info = InfoWrapper;
