import React, { useRef, useState, forwardRef } from "react";
import AuthForm from "./AuthFormWrapper.Component";
import { signup } from "../../api/auth";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { Button, Form, Loader } from "react-bulma-components";
import styles from "./AuthForm.module.scss";
import Avatar from "components/Atoms/Avatar";
import InputShowPassword from "components/InputShowPassword/InputShowPassword";

const SignUp = forwardRef(({ onSubmit, isLoading }, ref) => {
  const { handleSubmit, errors, control, getValues } = useForm({
    mode: "onBlur",
  });
  const [photo, setPhoto] = useState(
    "https://as2.ftcdn.net/v2/jpg/04/10/43/77/1000_F_410437733_hdq4Q3QOH9uwh0mcqAhRFzOKfrCR24Ta.jpg"
  );
  const [showPass, setShowPass] = useState(false);
  const [showIsPass, setShowIsPass] = useState(false);

  const imageHandler = async (event) => {
    await setPhoto(URL.createObjectURL(event.target.files[0]));
    ref.current.append("photo", event.target.files[0]);
  };

  const togglePasswordVisiblity = () => {
    setShowPass(!showPass);
  };

  const toggleRepeatPassword = () => {
    setShowIsPass(!showIsPass);
  };

  const rulesPassword = {
    required: "Ingresa tu contraseña correctamente.",
    minLength: {
      value: 8,
      message: "Debes ingresar 8 caracteres como mínimo.",
    },
  };

  const rulesRepeatPassword = {
    required: "Vuelve a ingresar la contraseña.",
    minLength: {
      value: 8,
      message: "Debes ingresar 8 caracteres como mínimo.",
    },
    validate: (value) =>
      value === getValues()["password"]
        ? true
        : "Las contraseñas no coinciden.",
  };

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <Form.Field>
        <Form.Control>
          <Controller
            name="photo"
            control={control}
            rules={{ required: false }}
            defaultValue={[]}
            render={(props) => {
              return (
                <div className={"file-input"}>
                  <input
                    id="photo"
                    type="file"
                    name="photo"
                    className={styles.input_file_photo}
                    accept=".png, .jpeg"
                    {...props}
                    value={props.value.filename}
                    onChange={imageHandler}
                  />
                </div>
              );
            }}
          />
        </Form.Control>
        {photo !== "" ? (
          <label className={styles.label_signup} htmlFor="photo">
            <Avatar width={100} height={100} rounded={true} src={photo} />
            <p className={styles.file_name}></p>
          </label>
        ) : (
          <label className={styles.label_signup} htmlFor="photo">
            {"Foto"}
            <p className={styles.file_name}></p>
          </label>
        )}
        {errors.photo && (
          <Form.Help color="danger">
            Ingresa tu usuario correctamente.
          </Form.Help>
        )}
      </Form.Field>

      <Form.Field>
        <Form.Label htmlFor="username">Nombre de usuario*</Form.Label>
        <Form.Control>
          <Controller
            name="username"
            autoComplete="username"
            control={control}
            render={({ value, onChange, onBlur }) => {
              return (
                <Form.Input value={value} onBlur={onBlur} onChange={onChange} />
              );
            }}
            rules={{ required: true }}
            aria-invalid={errors.username ? "true" : "false"}
            defaultValue=""
          />
        </Form.Control>
        {errors.username && (
          <Form.Help color="danger">
            Ingresa tu usuario correctamente.
          </Form.Help>
        )}
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="email">Email*</Form.Label>
        <Form.Control>
          <Controller
            name="email"
            autoComplete="off"
            control={control}
            render={({ value, onChange, onBlur }) => {
              return (
                <Form.Input value={value} onBlur={onBlur} onChange={onChange} />
              );
            }}
            rules={{ required: true }}
            aria-invalid={errors.email ? "true" : "false"}
            defaultValue=""
          />
        </Form.Control>
        {errors.username && (
          <Form.Help color="danger">Ingresa tu email correctamente.</Form.Help>
        )}
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="identifier">DNI*</Form.Label>
        <Form.Control>
          <Controller
            name="identifier"
            autoComplete="off"
            control={control}
            render={({ value, onChange, onBlur }) => {
              return (
                <Form.Input value={value} onBlur={onBlur} onChange={onChange} />
              );
            }}
            rules={{ required: true }}
            aria-invalid={errors.dni ? "true" : "false"}
            defaultValue=""
          />
        </Form.Control>
        {errors.username && (
          <Form.Help color="danger">Ingresa tu DNI correctamente.</Form.Help>
        )}
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="first_name">Nombres</Form.Label>
        <Form.Control>
          <Controller
            render={({ value, onChange, onBlur }) => {
              return (
                <Form.Input value={value} onBlur={onBlur} onChange={onChange} />
              );
            }}
            name="first_name"
            autoComplete="off"
            control={control}
            rules={{ required: false }}
            aria-invalid={errors.first_name ? "true" : "false"}
            defaultValue=""
          />
        </Form.Control>
        {errors.first_name && (
          <Form.Help color="danger">Error en el nombre</Form.Help>
        )}
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="last_name">Apellidos</Form.Label>
        <Form.Control>
          <Controller
            name="last_name"
            render={({ value, onChange, onBlur }) => {
              return (
                <Form.Input value={value} onBlur={onBlur} onChange={onChange} />
              );
            }}
            autocomplete="off"
            control={control}
            autoComplete="off"
            rules={{ required: false }}
            aria-invalid={errors.last_name ? "true" : "false"}
            defaultValue=""
          />
        </Form.Control>
        {errors.last_name && (
          <Form.Help color="danger">Error en el apellido.</Form.Help>
        )}
      </Form.Field>
      <FormProvider>
        <InputShowPassword
          control={control}
          text="Contraseña*"
          name="password"
          autoComplete="password"
          validate={errors.password}
          togglePasswordVisiblity={togglePasswordVisiblity}
          showPass={showPass}
          rules={rulesPassword}
          textErrors={errors.password?.message}
        />
      </FormProvider>
      <FormProvider>
        <InputShowPassword
          control={control}
          autoComplete="off"
          text="Repetir contraseña*"
          validate={errors.validatePassword}
          name="validatePassword"
          togglePasswordVisiblity={toggleRepeatPassword}
          showPass={showIsPass}
          rules={rulesRepeatPassword}
          textErrors={errors.validatePassword?.message}
        />
      </FormProvider>
      <Form.Field className="mt-4 mb-4 pb-5">
        <hr />
        <Button
          className="is-pulled-right"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              Accediendo... <Loader className="ml-4" />
            </>
          ) : (
            "Regístrame"
          )}
        </Button>
      </Form.Field>
    </form>
  );
});

export default function SignUpForm({ setUserData, goToHome, ...props }) {
  const [formError, setFormError] = useState(null);
  const {
    mutate,
    isPending: isLoading,
    isError,
  } = useMutation({
    mutationFn: signup,
    onError: (error) => {
      switch (error.request.status) {
        case 400:
          setFormError(
            "Ya existe registro con el mismo nombre de usuario o email."
          );
          break;
        default:
          setFormError(
            "Hubo un problema al intentar registrarte. Intenta de nuevo."
          );
      }
    },
    onSuccess: ({ data }) => {
      setUserData(data);
      goToHome();
    },
  });
  const formDataRef = useRef(new FormData());

  const onSubmit = async (body) => {
    formDataRef.current.append("email", body.email);
    formDataRef.current.append("identifier", body.identifier);
    formDataRef.current.append("first_name", body?.first_name);
    formDataRef.current.append("last_name", body?.last_name);
    formDataRef.current.append("password", body.password);
    formDataRef.current.append("username", body.username);
    formDataRef.current.append("validatePassword", body.validatePassword);

    setFormError(null);
    await mutate(formDataRef.current);
  };

  return (
    <div {...props}>
      <AuthForm.Info>
        {isError ? (
          <AuthForm.Error>{formError}</AuthForm.Error>
        ) : (
          <AuthForm.Progress isLoading={isLoading} />
        )}
      </AuthForm.Info>
      <SignUp onSubmit={onSubmit} isLoading={isLoading} ref={formDataRef} />
    </div>
  );
}
