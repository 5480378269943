import React, { useState, useEffect, useMemo } from "react";
import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel";
import HomeListCard from "../../components/Cards/HomeListCard";
import FilteredList from "./FilteredList";
import style from "./HomeFilteredList.module.scss";
import BlockActionAlert from "components/Alerts/BlockActionAlert";
import { useApiMutation } from "hooks/api.hook";
import Loading from "components/Loading/Loading";
import CarouselComponent from "components/Carousel/CarouselComponent";
import { config } from "@react-spring/web";
import "@brainhubeu/react-carousel/lib/style.css";

function CarouselAsyncWrapper({
  initialData = [],
  activeTab,
  apiUrl,
  apiParams = null,
}) {
  const [dataOrigin, setDataOrigin] = useState(initialData);
  const [firstLoad, setFirstLoad] = useState(false);

  const { mutate, isPending: isLoading } = useApiMutation(
    {
      url: apiUrl,
      config: {
        params: {
          ...apiParams.params,
          place_type: !activeTab ? null : activeTab,
        },
      },
    },
    {
      onSuccess: ({ data: results }) => {
        if (Array.isArray(results.results)) {
          console.log({ results });
          setDataOrigin(results.results);
        } else {
          // Maneja adecuadamente si no es un array
          setDataOrigin([]);
        }
      },
      onError: ({ error }) => {
        console.log({ error });
      },
    }
  );

  useEffect(() => {
    setFirstLoad(true);
  }, []);

  useEffect(() => {
    if (firstLoad) {
      mutate();
    }
    //eslint-disable-next-line
  }, [activeTab]);

  const memoizedData = useMemo(() => {
    if (Array.isArray(dataOrigin) && dataOrigin.length > 0) {
      return dataOrigin.map((item, index) => (
        <div className={`${style.filterCard}`} key={"memoizedData-" + index}>
          <HomeListCard data={item} />
        </div>
      ));
    }
    return null; // O manejar como consideres adecuado cuando no hay datos
  }, [dataOrigin]);

  if (isLoading) {
    return (
      <div className="column">
        <Loading />
      </div>
    );
  }

  if (!dataOrigin || dataOrigin.length === 0) {
    return (
      <div className="column">
        <BlockActionAlert
          message="No se han encontrado resultados."
          showAction={false}
        />
      </div>
    );
  }

  return (
    <Carousel
      plugins={[
        "infinite",
        "arrows",
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 3,
          },
        },
      ]}
      className={style.carousel}
      slidesPerPage={3}
      arrows
    >
      {memoizedData}
    </Carousel>
  );
}

export default function HomeFilteredList({
  title,
  discoverLink = "#",
  data = null,
  fetchQuery,
  fetchMethod,
  tabs,
  isAvailable = false,
  apiUrl,
  apiParams,
}) {
  const [activeTab, setActiveTab] = useState(0);
  console.log({ tabs });
  console.log({ data });

  return (
    <>
      <FilteredList
        tabs={tabs}
        onTabChange={setActiveTab}
        activeTab={activeTab}
        title={title}
        showTabs={data?.length}
      >
        <CarouselAsyncWrapper
          initialData={data}
          activeTab={activeTab}
          apiUrl={apiUrl}
          apiParams={apiParams}
        />
      </FilteredList>
    </>
  );
}
