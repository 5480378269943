import React, { useState, } from "react";
import style from "./SearchResultCard.module.scss";
import StarRanking from "components/Atoms/StarRanking";
import { MdSyncDisabled } from "react-icons/md";
import SvgIcon from "components/Atoms/SvgIcon";
import { ToastContainer } from "react-toastify";
import userStore from "contexts/userStore";
import { MdFavorite } from "react-icons/md";
import { useSetFavorite, useDeleteFavorite } from "hooks/api.hook";

export default function SearchResultCard({ data, goToPlace }) {
  const [isFavorite, setIsFavorite] = useState(data.is_favorite);

  const isLogged = userStore(state => state.isLogged)();

  const { handleFavorite } = useSetFavorite();
  const { deleteFavorite } = useDeleteFavorite();

  const handleClick = () => {
    if (data.is_favorite) {
      setIsFavorite(!data.is_favorite);
      deleteFavorite(data.place);
    } else {
      setIsFavorite(!data.is_favorite);
      handleFavorite(data.place);
    }
  };

  return (
    <div className="columns card is-gapless">
      <div className="column is-narrow">
        <figure className={style.venue}>
          <img src={data.place_logo} alt="" />
        </figure>
      </div>
      <div className="column">
        <div className={style.description}>
          <div>
            <h3>{data.name}</h3>
            <StarRanking ranking={data.rating} />
            <span>{data.edifice_name}</span>
            <small>{data.zone_name}</small>
          </div>
          <div className={style.actions}>
            <div>
              <SvgIcon>
                <MdSyncDisabled />
              </SvgIcon>
              <SvgIcon>
                <MdSyncDisabled />
              </SvgIcon>
            </div>
            <div>
              <button
                disabled={!isLogged}
                onClick={handleClick}
                className="button is-svg ml-4"
                aria-label="añadir a favoritos"
                title="añadir a favoritos"
              >
                <SvgIcon size="large">
                  <MdFavorite color={isFavorite ? "red" : "grey"} />
                </SvgIcon>
              </button>
              <button
                onClick={() => goToPlace(data.place)}
                className="button is-primary"
              >
                Ver más
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
