// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { onMessage, getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUyQZowkwTGayQBSEe696LSEKaGWkqbo0",
  authDomain: "espacios-4dde4.firebaseapp.com",
  projectId: "espacios-4dde4",
  storageBucket: "espacios-4dde4.appspot.com",
  messagingSenderId: "258866668545",
  appId: "1:258866668545:web:5c151576e6be84c4f10c00",
  measurementId: "G-WQCRGQGWCP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export { messaging, onMessage };
