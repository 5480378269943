import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import FilteredList from "components/Organisms/FilteredList";
// import { Columns } from "react-bulma-components";
import VenueCard from "components/Cards/VenueCard";
// import { Section, Container } from "react-bulma-components";
import API from "api/api";
import endpoints from "api/URLconstants";
import Loading from "components/Loading/Loading";
import BlockActionAlert from "components/Alerts/BlockActionAlert";
import { useApiMutation } from "hooks/api.hook";
import { useIsComponentLoaded } from "../../hooks/utility.hooks";
import { result } from "lodash-es";

function ListResults({ isLoading, dataOrigin }) {
  if (isLoading) {
    return (
      <div className="column">
        <Loading />
      </div>
    );
  }

  if (!dataOrigin.results.length) {
    return (
      <div className="column">
        <BlockActionAlert
          message="No se han encontrado resultados."
          showAction={false}
        />
      </div>
    );
  }

  return dataOrigin.results.map((card, index) => (
    <VenueCard data={card} key={index} />
  ));
}

function AsyncListWrapper({ data, tabs, endpoint }) {
  console.log({ data });

  const created = useIsComponentLoaded();
  const [dataOrigin, setDataOrigin] = useState(data || []);

  const [activeTab, setActiveTab] = useState(0);
  const { mutate, isPending } = useApiMutation(
    {
      url: endpoint,
      config: {
        params: {
          place_type: !activeTab ? null : activeTab,
        },
      },
    },
    {
      onSuccess: (results) => {
        console.log({ result });

        if (results && Array.isArray(results)) {
          setDataOrigin(results);
        } else if (results && results.items) {
          setDataOrigin(results.items); // Si los datos están dentro de `items`
        } else {
          setDataOrigin([]); // Asegúrate de no dejar `dataOrigin` vacío
        }
      },
    }
  );

  useEffect(() => {
    if (created) {
      mutate();
    }
    //eslint-disable-next-line
  }, [activeTab]);
  console.log({ dataOrigin });

  return (
    <>
      <FilteredList
        title="Espacios"
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        showLoadMore={data.next}
      >
        <ListResults isLoading={isPending} dataOrigin={data || []} />
      </FilteredList>
    </>
  );
}

export default function EdificeFilteredList({ id, tabs }) {
  const placesEndpoint = `${endpoints.edifice.list}${id}/places`;
  const getData = async (url) => {
    const { status, data } = await API.get(url.queryKey[0]);

    return data;
  };
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [placesEndpoint],
    queryFn: (url) => getData(url),
    refetchOnWindowFocus: false,
    retry: 1,
    staleTime: 1000 * 60 * 60 * 24,
  });

  if (isLoading) {
    return (
      <div className="section">
        <div className="container">
          <Loading />
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="section">
        <div className="container">
          <BlockActionAlert
            action={refetch}
            message="Hubo un problema al intentar cargar el listado de espacios."
            actionText="Reintentar"
          />
        </div>
      </div>
    );
  }

  return <AsyncListWrapper data={data} tabs={tabs} endpoint={placesEndpoint} />;
}
