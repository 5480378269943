import { useQuery } from "@tanstack/react-query";
import { getEdificeNames, getServices } from "api/edifice";
import { getPlaceTypes } from "api/placeTypes";

export const fetchInitialParams = async () => {
  const [places, edifices, services] = await Promise.all([
    getPlaceTypes(),
    getEdificeNames(),
    getServices(),
  ]);
  return { places, edifices, services };
};
export function useGetInitialParams() {
  return useQuery({
    queryKey: ["initialParams"],
    queryFn: fetchInitialParams,
    retry: 1,
    staleTime: 1000 * 60 * 60 * 24,
  });
}
