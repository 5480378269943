import React from "react";
import { Columns, Button, Form } from "react-bulma-components";
// import {
//   Select,
//   Label,
//   Help,
// } from "react-bulma-components/lib/components/form";

import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import style from "./HomeBookingForm.module.scss";
import { useHistory } from "react-router-dom";
import { stringify } from "query-string";
import { formatTime } from "utils/time";

export default function HomeBookingForm({ places, edifices }) {
  const history = useHistory();
  const { handleSubmit, control, register, setValue, watch, errors } = useForm({
    defaultValues: { date: "", place: "", edifice: "" },
  });

  const dateReceived = watch("date");

  function updateDate(e) {
    setValue("date", new Date());
  }

  const onSubmit = (data) => {
    const dataToPass = { ...data, date: formatTime(data?.date) };
    history.push(`/busqueda/${stringify(dataToPass)}`);
  };

  function datePickerActions() {
    return (
      <div className={style.datePickerActions}>
        <Button
          outlined
          color="primary"
          className="is-small"
          onClick={updateDate}
        >
          Cuanto antes
        </Button>
        <Button
          outlined
          color="primary"
          className="is-small"
          onClick={updateDate}
        >
          La semana que viene
        </Button>
        <Button
          outlined
          color="primary"
          className="is-small"
          onClick={updateDate}
        >
          No he decidido la fecha
        </Button>
      </div>
    );
  }

  return (
    <div className={`card ${style.homeBooking}`}>
      <h1>Reserva mesas, aulas, salas y más</h1>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Columns className={style.bookingForm}>
            <Columns.Column size={3}>
              <Form.Label htmlFor="place">¿Qué estás buscando?</Form.Label>
              <Controller
                name="place"
                rules={{
                  required: { value: true },
                }}
                render={(field) => (
                  <Form.Select
                    onChange={(e) => field.onChange(e.target.value)}
                    className={style.bookingField}
                    value={field.value}
                    name={field.name}
                    ref={field.ref}
                  >
                    <option disabled value="">
                      Selecciona tipo de lugar
                    </option>
                    {places.map((option) => (
                      <option key={"place-" + option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
                control={control}
                className={style.bookingField}
              />
              {errors?.place && (
                <Form.Help color="danger" className={style.textPosition}>
                  Selecciona tipo de lugar
                </Form.Help>
              )}
            </Columns.Column>
            <Columns.Column size={3}>
              <Form.Label htmlFor="edifice">¿En dónde?</Form.Label>
              <Controller
                name="edifice"
                rules={{ required: true }}
                render={(field) => (
                  <Form.Select
                    onChange={(e) => field.onChange(e.target.value)}
                    className={style.bookingField}
                    value={field.value}
                    name={field.name}
                    ref={field.ref}
                  >
                    <option disabled value="">
                      Selecciona edificio
                    </option>
                    {edifices.map((option) => (
                      <option key={"place-" + option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
                control={control}
                className={style.bookingField}
              />
              {errors?.edifice && (
                <Form.Help color="danger" className={style.textPosition}>
                  Selecciona edificio
                </Form.Help>
              )}
            </Columns.Column>
            <Columns.Column size={3}>
              <Form.Label htmlFor="date">¿Cuándo?</Form.Label>
              <Controller
                name="date"
                // register={register({ required: true })}
                control={control}
                rules={{ required: true }}
                onFocus={errors?.date}
                as={
                  <DatePicker
                    dropdownMode="select"
                    className={`input ${style.bookingField}`}
                    selected={dateReceived}
                    placeholderText="Selecciona fecha"
                    popperPlacement="bottom"
                    autoComplete="off"
                    popperModifiers={{
                      flip: {
                        behavior: ["bottom"],
                      },
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    }}
                  >
                    {datePickerActions()}
                  </DatePicker>
                }
              ></Controller>
              {errors?.date && (
                <Form.Help color="danger" className={style.textPosition}>
                  Selecciona fecha
                </Form.Help>
              )}
            </Columns.Column>
            <Columns.Column size={3}>
              <Button
                type="submit"
                className={style.bookingSearch}
                color="primary"
              >
                Buscar
              </Button>
            </Columns.Column>
          </Columns>
        </form>
      </div>
    </div>
  );
}
