import API from "./api";
import endpoints from "./URLconstants";

export const listAllEdifices = async () => {
  try {
    const response = await API.get(endpoints.edifice.list);
    if (response.status === 200) {
      return response.data; // Devuelve los datos de la respuesta
    } else {
      throw new Error("Failed to fetch edifices");
    }
  } catch (error) {
    console.error("Error fetching edifices:", error);
    return []; // Devuelve un valor predeterminado en caso de error
  }
  // return API.get(endpoints.edifice.list);
};

export const getEdificeNames = () => API.get(endpoints.edifice.getNames);

export function getEdificeInfo(slug) {
  return API.get(`${endpoints.edifice.list}${slug}`);
}

export function getServices(edifice = null) {
  return API.get(`${endpoints.edifice.services}`, { params: { edifice } });
}

export function getEdificePlaces(placeId, params = null) {
  return API.get(`${endpoints.edifice.list}${placeId}/places`, { params });
}
