import React from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import styles from "./InputShowPassword.module.css";

const InputShowPassword = ({
  text,
  togglePasswordVisiblity,
  value,
  rules,
  textErrors,
  name,
  validate,
  showPass,
  control,
  autoComplete
}) => {
  const { errors } = useForm({ mode: "onBlur" });

  return (
    <div className="field">
      <label className="label" htmlFor={name}>{text}</label>
      <div className={styles.container}>
        <Controller
          control={control}
          autoComplete={autoComplete}
          name={name}
          render={({ value, onChange, onBlur }) => {
            return (
              <input
                className={`input ${validate && errors[name] ? "is-danger" : ""}`}
                value={value}
                type={showPass ? "text" : "password"}
                onBlur={onBlur}
                onChange={onChange}
              />
            );
          }}
          rules={rules}
          aria-invalid={validate ? "true" : "false"}
          defaultValue=""
        />
        <div className={styles.icon_eye}>
          {value ? (
            <AiFillEyeInvisible onClick={togglePasswordVisiblity} />
          ) : (
            <AiFillEye onClick={togglePasswordVisiblity} />
          )}
        </div>
      </div>
      {validate && <p className="help is-danger" color="danger">{textErrors}</p>}
    </div>
  );
};

export default InputShowPassword;
