import React, { useContext, useRef } from "react";
import { useTransition, animated } from "@react-spring/web";
import useOnClickOutside from "use-onclickoutside";
import { UIContext } from "contexts/UIContext";
import style from "./Modal.module.scss";
/* import SvgIcon from "components/Atoms/SvgIcon";
import Button from "react-bulma-components/lib/components/button";
import { MdClose } from "react-icons/md"; */
export default function Modal({
  children,
  showActions = false,
  headerTitle,
  contentTitle,
  action,
  isOpen,
  size,
}) {
  const { closePortal } = useContext(UIContext);
  const ref = useRef(null);
  useOnClickOutside(ref, closePortal);

  const animProps = useTransition(isOpen, {
    config: { mass: 1, tension: 400, friction: 30 },
    from: { opacity: 0, marginTop: "-20px" },
    enter: { opacity: 1, marginTop: "0px" },
    leave: { opacity: 0, marginTop: "-20px" },
  });

  const overlayProps = useTransition(isOpen, {
    config: { mass: 1, tension: 400, friction: 30 },
    from: { opacity: 0 },
    enter: { opacity: 0.6 },
    leave: { opacity: 0, display: "none" },
  });

  return (
    <>
      {isOpen &&
        overlayProps((styleA, item) => (
          <animated.div
            key={"overlayProps-" + item}
            style={styleA}
            className={style.overlay}
            aria-hidden="true"
          />
        ))}

      {animProps((styleA, item) => {
        return (
          item && (
            <animated.div
              key={"animProps" + item}
              style={styleA}
              className={style.modal}
              ref={ref}
            >
              <div tabIndex="0"></div>
              <div role="dialog" aria-modal="true" aria-labelledby="">
                <div className={style.modalHead}>
                  {headerTitle && (
                    <h3 className={style.headerTitle}>headerTitle</h3>
                  )}
                  {/* <Button
                    className={style.close}
                    aria-label="cerrar"
                    onClick={closePortal}
                  >
                    <SvgIcon>
                      <MdClose />
                    </SvgIcon>
                  </Button> */}
                </div>
                <div className={style.modalBody}>
                  {contentTitle && (
                    <h3 className={style.contentTitle}>{contentTitle}</h3>
                  )}
                  <div
                    className={`${style.modalContent} ${
                      contentTitle ? "pt-4" : ""
                    }`}
                  >
                    {children}
                  </div>
                </div>
                {action && <div className={style.modalActions}>{action}</div>}
              </div>
              <div tabIndex="0"></div>
            </animated.div>
          )
        );
      })}
    </>
  );
}
