import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { props } from "bluebird";
import { Section, Container, Columns } from "react-bulma-components";
import Hero from "components/Banners/Hero";
import GridGallery from "components/Media/GridGallery";
import EdificeFilteredList from "components/Lists/EdificeFilteredList";
import Blueprint from "components/Blueprint";
import LoadingWide from "components/Loading/LoadingWide";
import GMap from "components/Map";
import styles from "./DetailEdifice.module.scss";
import Description from "components/Description/Description";
import { getEdificeInfo } from "api/edifice";
import { getPlaceTypes } from "api/placeTypes";
import { MdPlace, MdWatchLater } from "react-icons/md";
import { IoIosPeople, IoMdInformationCircle } from "react-icons/io";

const DetailEdifice = () => {
  const {
    params: { slug },
  } = useRouteMatch();

  const { data: response, isLoading } = useQuery({
    queryKey: ["edificeInfo"],
    queryFn: () => {
      return props({
        edifice: getEdificeInfo(slug),
        places: getPlaceTypes(),
      });
    },
    retry: 1,
    staleTime: 1000 * 60 * 60 * 24,
    // refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <LoadingWide />;
  }

  const aboutThis = (item) => [
    {
      title: "Ubicacion",
      icon: <MdPlace />,
      isBold: true,
      data: [item.address],
    },
    {
      title: "Horarios",
      icon: <MdWatchLater />,
      isBold: true,
      isSchedule: true,
      data: [item.schedule],
    },
    {
      title: "Capacidad",
      icon: <IoIosPeople />,
      isBold: true,
      data: item.max_spaces,
    },
    {
      title: "Servicios",
      icon: <IoMdInformationCircle />,
      isBold: true,
      isServices: true,
      data: [item.services],
    },
  ];

  return (
    <>
      <Hero background={response?.edifice?.data.cover} size="small" />
      <Section>
        <Container>
          <Columns>
            <Columns.Column className="is-two-thirds-desktop">
              <h1>{response.edifice.data.name}</h1>
              <ul className={styles.anchorList}>
                <li>
                  <a href="#espacios">Espacios</a>
                </li>
                <li>
                  <a href="#plano">Plano</a>
                </li>
                <li>
                  <a href="#mapa">Ubicación</a>
                </li>
                <li>
                  <a href="#info">Información</a>
                </li>
              </ul>
              <p>{response.edifice.data.description}</p>
            </Columns.Column>
            <Columns.Column>
              <GridGallery data={response.edifice.data.images} />
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      <Section id="espacios">
        <Container>
          <EdificeFilteredList tabs={response.places.data} id={slug} />
        </Container>
      </Section>
      <Section id="plano">
        <Container>
          {/*<Blueprint zones={response.edifice.data.zones} />*/}
        </Container>
      </Section>
      <Section id="mapa">
        <Container>
          <GMap
            data={{
              geo_coords: response.edifice.data.geo_coords,
              name: response.edifice.data.name,
              description: response.edifice.data.description,
            }}
          />
        </Container>
      </Section>
      <Section id="info">
        <Container>
          <Description
            items={aboutThis(response.edifice.data)}
            isTitle={"Acerca de este espacio"}
          />
        </Container>
      </Section>
    </>
  );
};

export default DetailEdifice;
