import React from "react";
import { MdSearch } from "react-icons/md";
import style from "./SearchResultsForm.module.scss";

export default function SearchResultsForm() {
  return (
    <div className={`columns is-gapless ${style.searchResults}`}>
      <div className="column is-hidden-touch is-narrow">
        <h2>Resultados:</h2>
      </div>
      <div className="column">
        <div className={`columns ${style.searchGroup}`}>
          <div className="column">
            <label className="label">
              <small>Ordenar por:</small>
              <div className="select is-small">
                <select className="is-small">
                  <option>Disponibles más pronto</option>
                </select>
              </div>
            </label>
          </div>
          <div className="column is-narrow">
            <div className="field is-action">
              <input
                className="input is-small"
                placeholder="Buscar espacio"
                aria-label="nombre del espacio"
              />
              <button className="button is-small is-dark" aria-label="Buscar espacio">
                <MdSearch />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
