import React from "react";


import styles from "./LoadingWide.module.scss";
import { PuffLoader } from "react-spinners";

const LoadingWide = (isWide = false) => {
  return (
    <div className="section">
      <div className="container">
        <div className={styles.loadingContainer}>
          <div className={styles.loadingMsg}>
            <PuffLoader />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingWide;
