import React from "react";
import SearchResultCard from "components/Cards/SearchResultCard";
import BlockActionAlert from "components/Alerts/BlockActionAlert";

export default function SearchResultsList({ results = [], goToPlace }) {
  console.log({ results });
  return results.length ? (
    results.map(result => (
      <SearchResultCard
        data={result}
        key={"result-" + result.id}
        goToPlace={goToPlace}
      />
    ))
  ) : (
    <BlockActionAlert
      message="Tu búsqueda no ha arrojado resultados. Intenta otra vez ajustando los parámetros."
      showAction={false}
    />
  );
}
