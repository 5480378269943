import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import LoadingWide from "components/Loading/LoadingWide";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./ChangePassword.module.scss";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { reset_password } from "api/auth";

import AuthForm from "pages/Login/AuthFormWrapper.Component";
import { PuffLoader } from "react-spinners";

const ChangePassword = () => {
  const {
    params: { uuid, token },
  } = useRouteMatch();
  const { handleSubmit, errors, control, getValues } = useForm();
  const history = useHistory();

  const { mutate, isLoading } = useMutation({
    mutationFn: reset_password,
    onError: (error) => {
      return error.message.includes(401)
        ? toast.error("Ha ocurrido un error vuelva a intentarlo más tarde!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          })
        : toast.error("Ha ocurrido un error vuelva a intentarlo más tarde!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
    },
    onSuccess: ({ data }) => {
      toast.success("Se ha cambiado la contraseña correctamente!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      /* setUserData(data); */
      setTimeout(() => {
        history.push("/login");
      }, 2000);
    },
  });

  const onSubmit = async (body) => {
    /* setFormError(null); */

    const payload = {
      query: {
        password: body.new_password,
        repeat_password: body.new_password2,
      },
      uuid: uuid,
      token: token,
    };

    await mutate(payload);
  };

  if (isLoading) {
    return <LoadingWide />;
  }

  return (
    <>
      <AuthForm>
        <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
          <div className="field">
            <label className="label" htmlFor="new_password">
              Ingrese Nueva contraseña
            </label>
            <Controller
              name="new_password"
              type="password"
              control={control}
              rules={{
                required: "Ingresa tu contraseña correctamente.",
                minLength: {
                  value: 8,
                  message: "Debes ingresar 8 caracteres como mínimo.",
                },
              }}
              aria-invalid={errors.new_password ? "true" : "false"}
              defaultValue=""
              render={({ value, onChange, onBlur }) => {
                return (
                  <input
                    className={`input`}
                    value={value}
                    type={"password"}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                );
              }}
            />
            {errors.new_password && (
              <p className="help is-danger" color="danger">
                {errors.new_password.message}
              </p>
            )}
          </div>

          <div className="field">
            <label className="label" htmlFor="new_password2">
              Repita contraseña
            </label>
            <Controller
              render={({ value, onChange, onBlur }) => {
                return (
                  <input
                    className={`input`}
                    value={value}
                    type={"password"}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                );
              }}
              name="new_password2"
              type="password"
              control={control}
              rules={{
                required: "Vuelve a ingresar la contraseña.",
                minLength: {
                  value: 8,
                  message: "Debes ingresar 8 caracteres como mínimo.",
                },
                validate: (value) =>
                  value === getValues()["new_password"]
                    ? true
                    : "Las contraseñas no coinciden.",
              }}
              aria-invalid={errors.new_password2 ? "true" : "false"}
              defaultValue=""
            />
            {errors.new_password2 && (
              <p className="help is-danger" color="danger">
                {errors.new_password2.message}
              </p>
            )}
          </div>

          <div className="field mt-4">
            <hr />
            <button
              className="button is-pulled-right"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  Accediendo... <PuffLoader />
                </>
              ) : (
                "Ingresar"
              )}
            </button>
          </div>
        </form>
      </AuthForm>
      <ToastContainer />
    </>
  );
};

export default ChangePassword;
