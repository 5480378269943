import React from "react";
import style from "./Hero.module.scss";

export default function Hero({ children, background, size = "medium" }) {
  return (
    <section
      className={`${style.hero} is-${size}`}
      style={{ backgroundImage: `url(${background})` }}
    >
      {children && (
        <div className={`container ${style.hero__content}`}>{children}</div>
      )}
    </section>
  );
}
