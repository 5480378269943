import React from "react";
//styles
import style from "./BlockActionAlert.module.scss";

export default function BlockActionAlert({
  action,
  actionText = "",
  message = "",
  showAction = true
}) {
  return (
    <div className={`notification is-warning is-light ${style.blockAction}`}>
      <button className="delete"></button>
      <div className="columns">

        <div className={`column ${style.alertText}`}>
          <p>{message}</p>
        </div>
        {showAction && (

          <div className="column is-narrow">
            <button className="button is-primary" onClick={action}>{actionText}</button>
          </div>
        )}

      </div>
    </div>

  );
}
