import React, { useEffect, useMemo } from "react";

import MainRoute from "./routes";

// context
import UIContextProvider from "contexts/UIContext";
import useResizeObserverHook from "hooks/resizeObserverHook";
import "./assets/css/style.scss";
import "@fortawesome/fontawesome-free/css/solid.min.css"; // Ajuste a la ruta correcta
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
import { toast, ToastContainer } from "react-toastify";
import Message from "components/messages/Message";

const queryClient = new QueryClient();

function AppWrapper({ children }) {
  const { ref, isMobile } = useResizeObserverHook();

  const memoWrapper = useMemo(() => {
    return (
      <div className={`App ${isMobile ? "mobile" : "non-mobile"}`} ref={ref}>
        {children}
      </div>
    );
  }, [isMobile]);

  return memoWrapper;
}

function App() {
  onMessage(messaging, (payload) => {
    console.log(payload.notification);
    toast(<Message notification={payload.notification} />);
  });
  const requestPermission = async () => {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });

      console.log("Token generate: ", token);
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
  };
  useEffect(() => {
    requestPermission();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <UIContextProvider>
        <AppWrapper>
          <MainRoute />
        </AppWrapper>
        <div id="modal-portal"></div>
        <ToastContainer />
      </UIContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
