import { useQuery } from "@tanstack/react-query";
import { search_filter_spaces } from "api/spaces-search";
import endpoints from "api/URLconstants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export const useGetSearchResults = () => {
  const { query } = useParams();
  console.log([endpoints?.places.spacesSearch, query]);

  return useQuery({
    queryKey: [endpoints?.places.spacesSearch, query],
    queryFn: () => search_filter_spaces(endpoints?.places.spacesSearch, query),
    staleTime: 1000 * 60 * 5,
  });
};
