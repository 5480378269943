import React from "react";
import style from "./CustomRadioButtonGroup.module.scss";
import { Button, Columns } from "react-bulma-components";
const CustomRadioButtonGroup = ({ isColumn, options, onPress, selected }) => {
  return options ? (
    <Columns.Column
      className={isColumn ? style.boxDurationColumn : style.boxDurationRow}
      desktop={{ size: 12 }}
      mobile={{ size: 12 }}
    >
      {options.map((option, index) => {
        return (
          <Button
            disabled={option.disabled}
            onClick={() => onPress(option.value)}
            className={selected === option.value ? "is-primary" : ""}
            key={"option-" + index}
          >
            {option.label}
          </Button>
        );
      })}
    </Columns.Column>
  ) : null;
};

export default CustomRadioButtonGroup;
