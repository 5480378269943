import React, { useState, useCallback } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { Link } from "react-router-dom";
// import Columns from "react-bulma-components/lib/components/columns";
import MarkerIcon from "assets/img/map-marker.svg";
import style from "./Map.module.scss";

const containerStyle = {
  width: "100%",
  height: "560px",
};

const coords = {
  lat: 40.416775,
  lng: -3.70379,
};

function CustomMarker({ data }) {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <Marker
      position={data.geo_coords}
      icon={MarkerIcon}
      onClick={() => setShowInfo(true)}
    >
      {showInfo && (
        <InfoWindow onCloseClick={() => setShowInfo(false)}>
          <div className={style.infoWindow}>
            <Link to={`edificio/${data.index}`}>{data.name}</Link>
            <p>{data.description.slice(0, 100)}...</p>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
}

export default function GMap({ data, dataToCoords }) {
  const centerBounds = useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds();

      if (data) {
        bounds.extend(
          new window.google.maps.LatLng(
            parseFloat(data.geo_coords.lat),
            parseFloat(data.geo_coords.lng)
          )
        );
      } else {
        dataToCoords?.forEach((coord) => {
          bounds.extend(
            new window.google.maps.LatLng(
              parseFloat(coord.geo_coords.lat),
              parseFloat(coord.geo_coords.lng)
            )
          );
        });
      }

      map.fitBounds(bounds);
    },
    [data, dataToCoords]
  );

  return (
    <div className="columns mt-6">
      <div className="column">
        <LoadScript googleMapsApiKey={process.env.REACT_APP_API_KEY_MAPS}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={10}
            center={coords}
            onLoad={centerBounds}
          >
            {dataToCoords ? (
              dataToCoords.map((marker, index) => (
                <CustomMarker data={{ ...marker, index }} key={marker?.name} />
              ))
            ) : (
              <CustomMarker data={{ ...data, index: 1 }} key={data?.name} />
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
}
