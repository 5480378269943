import React from "react";
import style from "./TabbedFilter.module.scss";

export default function TabbedFilter({
  filters,
  onSelect,
  activeTab,
  isLocked
}) {
  return (
    <ul className={`is-hidden-touch ${style.tabbedFilter}`}>
      {filters.map((filter, index) => (
        <li key={"filter-" + index}>
          <button disabled={isLocked} className={`button is-small ${activeTab === filter.id ? style.active : ""
            }`}
            onClick={() => onSelect(filter.id)}>
            {filter.name}
          </button>

        </li>
      ))}
    </ul>
  );
}
