import API from "./api";
import endpoints from "./URLconstants";

export function listUsers() {
  return API.get(endpoints.edifice.list);
}

export function getProfile(id) {
  return API.get(`${endpoints.user.list}${id.queryKey[1]}/profile/`);
}

export function getFavorites() {
  return API.get(`${endpoints.user.list}favorites/`);
}

export const createReview = (placeId, review, comment) =>
  API.post(endpoints.user.rate, {
    rate: review,
    place_id: placeId,
    comment: comment,
  });

export const createFavorite = (placeId) =>
  API.post(endpoints.user.favorite, {
    place_id: placeId,
  });

export const deleteFavoriteApi = (placeId) =>
  API.post(endpoints.user.delete_favorite, {
    place_id: placeId,
  });

export const modifyAccount = async (payload) => {
  API.patch(`${endpoints.user.list}${payload.id}/`, payload.query, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
