import React, { useState } from "react";
import AuthForm from "./AuthFormWrapper.Component";
import { recover_password } from "../../api/auth";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import styles from "./AuthForm.module.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PuffLoader } from "react-spinners";

function RecoverPassword({ onSubmit, isLoading, goBack }) {
  const { handleSubmit, errors, control } = useForm();

  return (
    <>
      <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
        {/*  <Field>
          <Label htmlFor="newPassword">Nueva contraseña</Label>
          <Control>
            <Controller
              as={Input}
              name="newPassword"
              control={control}
              rules={{ required: true }}
              aria-invalid={errors.email ? "true" : "false"}
              defaultValue=""
            />
          </Control>
          {errors.username && (
            <Help color="danger">Ingresa una nueva contraseña.</Help>
          )}
        </Field> */}
        <div className="field">
          <label className="label" htmlFor="email">
            Correo electrónico*
          </label>

          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            aria-invalid={errors.email ? "true" : "false"}
            defaultValue=""
            render={({ value, onChange, onBlur }) => {
              return (
                <input
                  className="input"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                />
              );
            }}
          />

          {errors.username && (
            <p className="help is-danger" color="danger">
              Ingresa tu email correctamente.
            </p>
          )}
        </div>
        <div className="field mt-4 o-h">
          <hr />
          <button
            className="button is-pulled-right"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                Accediendo... <PuffLoader />
              </>
            ) : (
              "Recuperar contraseña"
            )}
          </button>
        </div>
      </form>
      <div>
        <button
          className="button is-pulled-right as-link mt-2"
          type="button"
          onClick={goBack}
        >
          <small>Regresar al registro</small>
        </button>
      </div>
      <ToastContainer />
    </>
  );
}

export default function RecoverPasswordForm({ setUserData, goBack, ...props }) {
  const [formError, setFormError] = useState("");
  const { mutate, isLoading, isError } = useMutation({
    mutationFn: recover_password,
    onError: (error) => {
      if (error) {
        setFormError(
          "El correo electrónico ingresado no se encuentra registrado."
        );
      }
    },
    onSuccess: ({ data }) => {
      toast.success("Hemos enviado un correo para restablecer tu contraseña!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    },
  });

  const onSubmit = async (body) => {
    setFormError(null);
    await mutate(body);
  };

  return (
    <div {...props}>
      <AuthForm.Info>
        {isError ? (
          <AuthForm.Error>{formError}</AuthForm.Error>
        ) : (
          <AuthForm.Progress isLoading={isLoading} />
        )}
      </AuthForm.Info>
      <RecoverPassword
        onSubmit={onSubmit}
        isLoading={isLoading}
        goBack={goBack}
      />
    </div>
  );
}
