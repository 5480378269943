import axios from "axios";
import { BASE_URL } from "../constants.js";

const headers = {
  // "Content-Type": "application/json"
};

const API = axios.create({
  baseURL: BASE_URL,
  headers: headers,
});

API.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("authToken"));
    if (token) {
      config.headers.Authorization = `JWT ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      const refresh_token = JSON.parse(localStorage.getItem("refreshToken"));
      if (refresh_token) {
        const payload = { refresh: refresh_token };
        console.log(payload);
        try {
          const { data } = await axios.post(
            BASE_URL + "/refresh-token/",
            payload
          );
          localStorage.setItem("authToken", data.access);
          localStorage.setItem("refreshToken", data.refresh);
          API.defaults.headers["Authorization"] = "JWT " + data.access;
          originalRequest.headers["Authorization"] = "JWT " + data.access;
          return API(originalRequest);
        } catch (error) {
          localStorage.clear();
          window.location = "/";
        }
      } else {
        localStorage.clear();
        window.location = "/";
      }
    }
    return Promise.reject(error);
  }
);

export default API;
