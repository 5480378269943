import React from "react";
import { createRoot } from "react-dom/client";
// import 'bulma/css/bulma.min.css';

import App from "./App";

// index.js
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js", { type: "module" })
    .then((registration) => {
      console.log("Service Worker registrado con éxito:", registration.scope);
    })
    .catch((error) => {
      console.error("Error al registrar el Service Worker:", error);
    });
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
