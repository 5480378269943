import React from "react";
import style from "./CheckBoxField.module.scss";

export default function CheckBoxField({ isChecked, onChange, label, checked }) {
  return (
    <div className={`field ${style.checkField}`}>
      <label class="checkbox">
        <input type="checkbox" value={isChecked} onChange={onChange} checked={checked} />
        {label}
      </label>
    </div>
  );
}
