import endpoints from "./URLconstants";
import API from "./api";

export function avTwoDaysAround(params) {
  return API.get(`${endpoints.availability.twoDaysAround}`, { params });
}

export function availableToday(url, params) {
  return API.get(url, { params });
}

export function searchAvailable(url, params) {
  return API.get(
    `${endpoints.availability.base}${url.queryKey[0]}?${url.queryKey[1]}`
  );
}

export function searchAvailableSpace(url, edifice, place) {
  return API.get(
    `${endpoints.availability.base}${url}?&edifice=${edifice}&place=${place}`
  );
}
