import React from "react";
import { Link } from "react-router-dom";
// import Columns from "react-bulma-components/lib/components/columns";
import { MdMap, MdLocalPhone, MdEmail } from "react-icons/md";
import style from "./HomeBuildingList.module.scss";
import { Columns } from "react-bulma-components";

function HomeBuildingItem({ data }) {
  return (
    <Columns className={style.item}>
      <Columns.Column size={5}>
        <div className={style.image}>
          <img alt="" src={data.cover} />
        </div>
      </Columns.Column>
      <Columns.Column size={7} className={style.info}>
        <Columns>
          <Columns.Column>
            <h3>{data.name}</h3>
            {false && <span>{data.address}</span>}
            <p>{data.description}</p>
          </Columns.Column>
          <Columns.Column narrow className={style.actions}>
            <ul>
              <li>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${data.geo_coords.lat},${data.geo_coords.lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="ver ubicación"
                >
                  <MdMap />
                </a>
              </li>
              <li>
                <a
                  href={`tel:${data.phone}`}
                  aria-label="obtener número telefónico"
                >
                  <MdLocalPhone />
                </a>
              </li>
              <li>
                <a href={`mailto:${data.email}`} aria-label="enviar correo">
                  <MdEmail />
                </a>
              </li>
            </ul>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column className="text-center--mobile">
            <Link to={`edificio/${data.id}`}>MÁS INFORMACIÓN</Link>
          </Columns.Column>
        </Columns>
      </Columns.Column>
    </Columns>
  );
}

function HomeBuildingItems({ data }) {
  return (
    <div>
      {data?.map((item, index) => {
        return (
          <HomeBuildingItem
            data={{ ...item, id: item.id }}
            key={"HomeBuildingItems-" + item.id}
          />
        );
      })}
    </div>
  );
}

export default function HomeBuildingList({ data }) {
  return (
    <div>
      <Columns>
        <Columns.Column>
          <h2 className="block__heading">Edificios</h2>
        </Columns.Column>
      </Columns>
      <HomeBuildingItems data={data} />
    </div>
  );
}
