import React from "react";

import style from "./Description.module.scss";
import { times } from "lodash-es";

const Description = (props) => {
  const {
    isMenu,
    isTitle,
    isSubtitle,
    isUnderlineSubitle,
    items,
    hasPhoto,
    hasButton,
    hasPaddingLeft = true,
    hasNotPaddingBottom,
  } = props;

  const DescriptionRow = ({ item }) => {
    const { icon, title, isBold, data, isSchedule, isServices, slug } = item;
    return (
      <>
        <div
          mobile={{ size: 12 }}
          tablet={{ size: 12 }}
          desktop={{ size: 12 }}
          className={`column ${
            hasPaddingLeft
              ? style.columnIsNotMenu
              : hasNotPaddingBottom
                ? style.hasNotPaddingBottom
                : style.columnIsMenu
          }`}
        >
          <div className={style.formPartHeader}>
            <div>{icon}</div>
            {isBold ? (
              <h4>{title}</h4>
            ) : isMenu ? (
              <a href={slug}>
                <h3>{title}</h3>{" "}
              </a>
            ) : (
              <h3>{title}</h3>
            )}
          </div>
        </div>
        <div
          mobile={{
            size: 12,
          }}
          tablet={{ size: 12 }}
          desktop={{
            size: 12,
          }}
          className={`column ${style.formPartBody}`}
        >
          <div>
            {isSchedule
              ? times(data[0]?.length, (index) => {
                  return <p key={index}>{data[0][index]}</p>;
                })
              : ""}
          </div>
          {isServices ? (
            <div className={style.formPartServices}>
              {times(data[0]?.length, (index) => {
                return (
                  <div key={index}>
                    {" "}
                    {/* {data[index].icon} */} <p>{data[0][index].service}</p>{" "}
                  </div>
                );
              })}{" "}
            </div>
          ) : (
            ""
          )}
          {!isSchedule && !isServices ? <p>{data}</p> : ""}
        </div>
      </>
    );
  };

  const BoxTitle = () => {
    return (
      <div
        mobile={{
          size: 12,
        }}
        tablet={{ size: 12 }}
        desktop={{
          size: 12,
        }}
        className={`column ${hasPhoto ? style.descriptionHeaderWithPhoto : style.descriptionHeader}`}
      >
        <div>{hasPhoto}</div>

        <h1>{isMenu}</h1>
        <h2>{isTitle}</h2>
        <h3>{isUnderlineSubitle}</h3>
        <h4>{isSubtitle}</h4>
      </div>
    );
  };

  const BoxCard = () => {
    return (
      <>
        <BoxTitle />
        <div
          mobile={{
            size: 12,
          }}
          tablet={{ size: hasButton ? 9 : 12 }}
          desktop={{
            size: hasButton ? 9 : 12,
          }}
          className={`column ${
            hasNotPaddingBottom
              ? hasPhoto
                ? style.boxDataColumnWithPhoto
                : style.boxDataColumn
              : ""
          }`}
        >
          {times(items.length, (index) => {
            return <DescriptionRow key={index} item={items[index]} />;
          })}
        </div>
        {hasButton ? (
          <div
            mobile={{
              size: 12,
            }}
            tablet={{ size: 3 }}
            desktop={{
              size: 3,
            }}
            className={`column ${style.descriptionButton}`}
          >
            {/*  <h4>¡Todo listo!</h4>
            <Button type="submit" color="primary">
              Reservar
            </Button> */}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <div className="columns" breakpoint="mobile">
      <BoxCard />
    </div>
  );
};

export default Description;
